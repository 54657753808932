import React from "react"
import { Link } from "gatsby"
import IgLogo from "../content/images/Instagram.webp"
import FbLogo from "../content/images/facebook.webp"
import CcLogo from "../content/images/canvas-craze-logo-removebg.webp"

const TopBar = () => (
  <div className="social-bar-container">
    <div className="social-bar">
      <ul>
        <li><a href="https://instagram.com"><img src={IgLogo} /></a></li>
        <li><a href="https://facebook.com"><img src={FbLogo} /></a></li>
      </ul>
    </div>
  </div>
)

const Header = ({ siteTitle }) => (
  <header>
    <TopBar />
    <div id="header-logo"><img src={CcLogo} /></div>
    <div id="wrappingmemories"><a href='https://www.instagram.com/explore/tags/wrappingmemories/'>#wrappingmemories</a></div>
    <div id="menu">
      <ul>
        <li><Link to='/'>Home</Link></li>
        <li><Link to='/gallery'>Gallery</Link></li>
        <li><Link to='/info'>Info</Link></li>
        <li><Link to='/contact-us'>Contact Us</Link></li>
      </ul>
    </div>
  </header>
)

export default Header
