import React from "react"
import { Link } from 'gatsby'
import MyGoogleMap from "./google_map.js"
import XwyzLogo from "../content/images/xwyz-logo.webp"


const FooterBar = () => (
  <div id="footer-bar">
      <div className="footer-flex-section">
        <span>©2020 Canvas Craze</span>
      </div>
      <div className="footer-flex-section">
        <a href="mailto:davidjbaker1994@gmail.com"><span>Site by</span><img id="xwyz-logo" src={XwyzLogo}/></a>
      </div>
      <div className="footer-flex-section">
        <ul>
          <li><Link to='/terms-and-conditions'>Terms</Link></li>
          <li> | </li>
          <li><Link to='/privacy'>Privacy</Link></li>
        </ul>
      </div>       
  </div>
)

const Footer = () => (
  <div id="footer-container">
      <div id="footer-section-left">
          <div id="map-container">
            <MyGoogleMap id="my-google-map" /> 
          </div> 
      </div>  
      <div id="footer-section-right">    
          <div id="footer-info">
            <p>500 Belwood Rd., Suite 8 Calhoun, GA 30701</p>
            <p>(706)270-1712</p>
            <p>canvascraze1@gmail.com</p>
            <p>Lori Woodall<br/> Owner / Operator</p>
            <p>Genia Kirby<br/> Owner / Operator</p>         
          </div> 
      </div>    
      <FooterBar />
  </div>
)


export default Footer
